import React from "react";
import { graphql } from "gatsby";
import ServiceTemplate from "../../templates/service-template";

const ServicePage = ({ data: { service } }) => (
  <ServiceTemplate service={service} />
);

export default ServicePage;

export const query = graphql`
  query ($id: String!, $language: String!) {
    service: servicesJson(id: { eq: $id }) {
      id: jsonId
      slug
      datePublished
      dateModified
      image
      image2
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
