import React from "react";
import Title from "./typography/Title";
import { Disclosure } from "@headlessui/react";
import { ArrowCircleDownIcon } from "@heroicons/react/outline";
import Section from "./layout/Section";
import { Pattern } from "./Pattern";
import BodyText from "./typography/BodyText";
import { TitleHeading } from "./typography/TitleHeading";
import { useAnimation } from "../hooks/useAnimate";

interface DisclosureProcessProps {
  tag: string;
  title: string;
  steps: {
    title: string;
    description: string | React.ReactNode;
  }[];
  left?: boolean;
  theme?: "white" | "gray";
}

export const DisclosureProcess: React.FunctionComponent<
  DisclosureProcessProps
> = ({ tag, title, steps, left = true, theme = "white" }) => {
  const [bouncing, setBouncing] = React.useState(true);
  const { id, animate } = useAnimation();

  return (
    <Section theme={theme} id={id}>
      <div className="lg:flex">
        <div
          {...animate("fade-right")}
          className={`w-full mb-8 lg:mb-36 lg:w-1/3 ${
            left ? "lg:order-last lg:ml-24 xl:ml-36" : "lg:mr-24 xl:mr-36"
          }`}
        >
          <TitleHeading theme="gray" tag={tag} title={title} />
        </div>
        <div className="lg:w-2/3 divide-y divide-solid divide-gray-300">
          {steps.map((step, index) => (
            <div {...animate("fade-left", 100 * index)}>
              <Disclosure
                as="div"
                defaultOpen={index === 0}
                key={`ds-${index}`}
              >
                <Disclosure.Button className="w-full">
                  {({ open: isOpen }) => (
                    <div
                      className="my-4 w-full text-left flex justify-between items-center"
                      onClick={() => setBouncing(false)}
                    >
                      <div className="flex items-top ">
                        <span className="inline-block w-12 sm:w-24 text-primary text-center text-lg sm:text-xl font-extrabold shrink-0">
                          0{index}
                        </span>
                        <Title small>{step.title}</Title>
                      </div>
                      <ArrowCircleDownIcon
                        className={`h-6 w-6 text-gray-500 transform-gpu transition-transform duration-150 ${
                          isOpen
                            ? "rotate-180"
                            : bouncing && index === 1
                            ? "animate-bounce"
                            : ""
                        }`}
                      />
                    </div>
                  )}
                </Disclosure.Button>

                <Disclosure.Panel className="mb-6 ml-12 sm:ml-24">
                  <BodyText mb={0} justify={false}>
                    {step.description}
                  </BodyText>
                </Disclosure.Panel>
              </Disclosure>
            </div>
          ))}
        </div>
      </div>
      <Pattern
        type="square"
        className={`hidden lg:block absolute h-1/2 w-1/3 bottom-0 ${
          left ? "right-0" : "left-0"
        } text-gray-200 z-10`}
      ></Pattern>
    </Section>
  );
};
