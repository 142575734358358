import React, { FunctionComponent } from "react";
import { HeroCta } from "../cta/HeroCta";
import { Pattern } from "../Pattern";

import HeroTitle from "../typography/HeroTitle";
import LeadText from "../typography/LeadText";
import TitleTag from "../typography/TitleTag";

type ImageHero3Props = {
  tag: string;
  title: JSX.Element;
  description: string | JSX.Element;
  image: string;
  cta?: JSX.Element;
  dark?: boolean;
};

const ImageHero3: FunctionComponent<ImageHero3Props> = ({
  tag,
  title,
  description,
  image,
  dark = false,
  cta = <HeroCta />,
}) => {
  return (
    <div
      className={`w-full ${dark ? "bg-gray-900" : "bg-gray-100"} mb-14 lg:mb-0`}
    >
      <section className="container relative px-5 lg:px-12 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-x-12 items-center">
        <div className="py-12 md:py-24 z-30">
          <TitleTag>{tag}</TitleTag>
          <HeroTitle dark={dark}>{title}</HeroTitle>
          <div className="mt-3 lg:mt-5 lg:max-w-xl">
            <LeadText dark={dark}>{description}</LeadText>
          </div>
          {cta}
        </div>
        {!dark && (
          <Pattern
            type="square"
            className="hidden lg:block absolute h-40 w-1/3 -bottom-16 left:0 xl:-left-20 text-gray-200 opacity-70 z-20"
          ></Pattern>
        )}
        <div className="items-center flex -mb-14 lg:mb-0">
          <img
            className="w-full mx-auto max-h-[400px] relative z-20"
            src={image}
            alt="Hero image"
          />
        </div>
      </section>
    </div>
  );
};

export default ImageHero3;
